import React, { Component, Fragment } from "react";
import Loader from "react-loader";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  Table,
} from "reactstrap";
import ReactDatatable from "@ashvin27/react-datatable";
import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
} from "availity-reactstrap-validation";
import {
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import {
  ApiUrl,
  ProductName,
  ToastAutoClose,
  AttachementsUrl,
} from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import Select from "react-select";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import ProfileSettings from "../Authentication/profile-sidebar";
import Dropdownaction from "./Dropdown";
import AttachCampaign from "./AttachCampaign";
import $ from "jquery";
import swal from 'sweetalert';
export default class PhoneNumbers extends Component {
  constructor(props) {
    super(props);


    this.state = {
      records: [],
      selected_phone_arr: [],
      total_pages: 0,
      del_records: [],
      total_pages_del: 0,
      modal: false,
      updatemodal: false,
      record: {},
      filter: {},
      page_name: "Caller ID",
      customDate: true,
      activeTab: "1",
      loaded: true,
      phone_records: {},
      seach_by_type: "areaCode",
      sliderMessage: 'Attached to the Campaign',
      country_code: "US",
      phone_type: "local",
      seach_by_value: "",
      unlockFlow: {
        groups: []
      },
      loader: false,
    };
    this.columns = [
      {
        text: "Phone Number",
        key: "phone_number",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              {record.phone_number}
            </Fragment>
          );
        },
      },
       {
        text: "Name",
        key: "phone_name",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              {record.phone_name}
            </Fragment>
          );
        },
      },
      {
        text: "Campaign",
        key: "campaign_name",
        sortable: false,
        cell: (record, index) => {
          return (
            <Fragment>
              {record.campaign_name}
            </Fragment>
          );
        },
      },
      {
        key: "action",
        text: "Action",
        cell: (record, index) => {
          return (
            <Fragment>
             {record.is_locked == "Yes" ? (
                <>
                 <Link
                  className="btn btn-primary btn-sm mb-1  mr-5"
                 
                  onClick={this.RevokeCampaignAccess.bind(this, record, index)}
                >
                 Detach from this Campaign
                </Link>
                </>
              ):(
                <button
                  type="button"
                  color="info"
                  className="btn btn-primary btn-sm mb-1 mr-5"
                  onClick={() => { this.setState({ sliderMessage: 'Attached to the Campaign', show4Slider: true, page2Class: 'slide-in', sliderShown: 'show4Slider', editId: record.id }) }}
                >
                 Attached to the Campaign
                </button>
              )}
               
              
                  <Link
                    className="btn btn-danger mb-1  btn-sm"
                    onClick={this.deleteRecord.bind(this, record, index)}
                  >
                   Delete
                  </Link>
             
            </Fragment>
          );
        },
      },
    ];
    this.config = {
      key_column: "sr",
      page_size: 100,
      length_menu: false,
      show_filter: true,
      show_pagination: true,

      button: {
        excel: false,
        print: true,
        csv: true,
      },
    };
  }

  componentDidMount() {
    this.geActivePhones("", "");
  }
 deleteRecord = (record, index) => {
    swal({
      title: "Are you sure?",
      text: "You want to permanently delete this phone number? This action cannot be undone, and the phone number will be unrecoverable",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + 'user/delete/phone_number', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ` + uToken()
            },
            body: JSON.stringify({ 'id': record.id }),
          })
            .then((response) => response.json())
            //Then with the data from the response in JSON...
            .then((data) => {
              if (data.status === true) {
                  this.geActivePhones("", "");
                  swal({
                    text: "Phone number deleted successfully",
                    icon: "success",
                  });
              } else {
                toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
              }
            })
            //Then with the error genereted...
            .catch((error) => {
              console.error('Error:', error);
            });
        }
      });
  }
  RevokeCampaignAccess = (record, index) => {
     this.setState({ sliderMessage: ' Detach from this Campaign'});
    swal({
      title: "Are you sure?",
      text: "You want to unlink the assigned campaign from this phone number? This action will remove the campaign's access to the number.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((confirmation) => {
        if (confirmation) {
          fetch(`${ApiUrl}` + "revoke/campaign/access", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ` + uToken(),
            },
            body: JSON.stringify({ 'id': record.id }),
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === true) {
                this.geActivePhones("", "");
                  swal({
                    text: "Phone number detected from campaign successfully",
                    icon: "success",
                  });
              } 
            })
            .catch((error) => {
              console.error("Error:", error);
            });
       }
      });
  }
  handleSubmitUnlockFlow = async (event, values) => {
    values.type = 'submit';
    values.show_notify = true;
    this.getUnlockFlow(values);
  }

  geActivePhones = (queryString = "", data) => {
    fetch(`${ApiUrl}` + "get/user/phone/numbers" + queryString, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ data, 'phone_status': "Active" }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            total_pages: response.data.total,
            records: response.data.records,
          });
         console.log(this.state.records,'here is records');
        } else {


          toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };
 
  tableChangeHandlerActive = (data) => {
    let queryString = Object.keys(data)
      .map((key) => {
        if (key === "sort_order" && data[key]) {
          return (
            encodeURIComponent("sort_order") +
            "=" +
            encodeURIComponent(data[key].order) +
            "&" +
            encodeURIComponent("sort_column") +
            "=" +
            encodeURIComponent(data[key].column)
          );
        } else {
          return encodeURIComponent(key) + "=" + encodeURIComponent(data[key]);
        }
      })
      .join("&");
    this.geActivePhones("?" + queryString, "");
  };

  cancelSlider = async () => {
    this.geActivePhones("", "");
    this.setState({
      page2Class: 'slide-out2',
      editId: '',
      sliderShown: ''
      // showSlider: false,
    })
  };
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <Row>
          <Col lg="12">
            <Row>
              <Col sm={6}>
                <div className="page-title-box">
                  <h4> {this.state.page_name} </h4>
                  <ol className="breadcrumb m-0">
                    <li key={0} className="breadcrumb-item active">
                      {ProductName}
                    </li>
                    <li key={1} className="breadcrumb-item">
                      <Link to="#"> {this.state.page_name} </Link>
                    </li>
                  </ol>
                </div>
              </Col>
               <Col sm={6}>
                  <div className="input-group justify-content-end">
                      <Link
                        type="button"
                        color="info"
                        className="btn btn-info mr-5"
                        to={"/caller/numbers/purchase"}
                      >
                        <i className="mdi mdi-plus"> </i>
                        Buy a Number
                      </Link>
                  </div>
                </Col>
            </Row>

            <ToastContainer />
              { this.state.show4Slider && this.state.sliderShown == 'show4Slider' && (
                  <div className={`page3 ${this.state.page2Class} custom-scroll`} >
                    <div>
                      <div className="modal-head top-heading">
                        <h2>{this.state.sliderMessage}</h2>
                        <button onClick={() => { this.setState({ page2Class: 'slide-out2' }); }} className="btn btn-link text-white">
                          <i className="fas fa-times"></i>
                        </button>
                      </div>
                      <AttachCampaign cancelSlider={this.cancelSlider} id={this.state.editId} />
                    </div>
                  </div>
                )
              }
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <ReactDatatable
                      key={'s'}
                      config={this.config}
                      records={this.state.records}
                      columns={this.columns}
                      dynamic={true}
                      total_record={this.state.total_pages}
                      onChange={this.tableChangeHandlerActive}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
